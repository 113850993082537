import React, { useState, useMemo, useEffect, Fragment, useRef } from 'react'
import {
  Card,
  CardHeader,
  CardContent,
  CardFooter,
  CardTitle,
} from 'shadcn-components/ui/card'
import { Button } from 'shadcn-components/ui/button'
import { Textarea } from 'shadcn-components/ui/textarea'
import { useQuery } from 'react-query'
import { useHistory, useLocation } from 'react-router-dom'
import apiStripe from 'services/api/Stripe'
import { DateTime } from 'luxon'
import { sortByField } from 'services/Utils'
import {
  segmentCheckoutStarted,
  segmentCheckoutCancelled,
} from 'services/Segment'
import * as Sentry from '@sentry/react'
import useStripe from 'services/hooks/useStripe'
import useStripeSetup from 'services/hooks/useStripeSetup'
import Payment from '../payment/Payment'
import { LoadingSpinner } from 'shadcn-components/ui/spinner'
import CustomTable from 'components/CustomTable/CustomTable'
import { Badge } from 'shadcn-components/ui/badge'
import { useToast } from 'shadcn-components/ui/use-toast'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from 'shadcn-components/ui/dialog'
import apiUsers from 'services/api/User'

const Billing = ({ user }) => {
  const { toast } = useToast()
  const params = new URLSearchParams(useLocation().search)
  const [sessionId] = useState(params.get('session_id'))
  const [canceled] = useState(params.get('canceled'))
  const history = useHistory()
  const { setup, isLoading } = useStripeSetup()
  const stripe = useStripe()

  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState('')
  const [session, setSession] = useState({})

  const [prices, setPrices] = useState([])
  const [features, setFeatures] = useState([])

  const [stripeCustomerId, setStripeCustomerId] = useState(
    user?.company?.stripe_customer_id
  )
  const [invoices, setInvoices] = useState([])

  const [stripeClientReferenceId, setStripeClientReferenceId] = useState('') // used by rewardful

  const [userUpgradePrice, setUserUpgradePrice] = useState({})
  const successCardEndRef = useRef(null)

  const [showBillingModal, setShowBillingModal] = useState(false)

  const [requestCallLoading, setRequestCallLoading] = useState(false)
  const [manageBillingLoading, setManageBillingLoading] = useState(false)
  const [feedback, setFeedback] = useState('')

  useEffect(() => {
    if (setup) {
      setPrices(setup.prices?.sort(sortByField('unit_amount')))
      setFeatures(setup.features)
      setUserUpgradePrice(getUserUpgradePrice(user?.company, setup.prices))
    }
  }, [setup, user.company])

  // Get Stripe publishable key (and prices/products) to initialize Stripe.js
  const { data: invs } = useQuery(
    'GET/stripe/customer-upcoming-invoices',
    () => apiStripe.getCustomerUpcomingInvoices(stripeCustomerId),
    {
      // staleTime: 1440 * 60 * 1000, // 60 minutes
      enabled: !!stripeCustomerId, // The query will not execute until the primarySellerId exists
      onSuccess: (invs) => {
        setInvoices(invs?.data.invoices)
      },
    }
  )

  const getUserUpgradePrice = (company, ps) => {
    var subs = company.stripe_subscription_items
    if (ps) {
      const sub = subs.find((sub) =>
        ['active', 'trialing', 'past_due'].includes(sub.subscription_status)
      )
      var upgradePrice
      if (sub) {
        const upgradeProductName = prices?.find(
          (price) => price.id === sub.price_id
        )?.product_metadata.upgrade_product_name
        if (upgradeProductName) {
          upgradePrice = ps.find(
            (price) =>
              price.product_name === upgradeProductName &&
              price.recurring_interval === sub.price_recurring_interval
          )
        }
      } else {
        if (
          [
            '07617a50-e0c9-45f2-bf8e-396918ef3d74', // Profitable Pineapple Ads
            '3c01a454-d498-407e-83f5-0fe1646bd9ae', // Click Convert
            '55eef060-9b0c-453f-b090-faf00044b1c8', // ZGi Online Inc.
          ].includes(company.public_id)
        ) {
          upgradePrice = ps
            .sort(sortByField('unit_amount'))
            .find((price) => price.product_name === 'Custom')
        } else {
          // default to first metered pay monthly product in price stack
          upgradePrice = ps
            .sort(sortByField('unit_amount'))
            .find(
              (price) =>
                price.recurring_usage_type === 'metered' &&
                price.recurring_interval === 'month'
            )
        }
      }
      return upgradePrice
    }
    return null
  }

  const pricingCardBtnClick = async (stripe, e) => {
    const priceId = e.target.value
    if (!priceId) {
      e.preventDefault()
      return
    }

    try {
      setLoading(true)
      const usageType = prices?.find(
        (price) => price.id === priceId
      )?.recurring_usage_type
      const data = {
        price_id: priceId,
        usage_type: usageType || '',
        customer_id: user.company.stripe_customer_id,
        customer_email: user.email,
        user_public_id: user.public_id,
        company_public_id: user.company.public_id,
        client_reference_id: stripeClientReferenceId,
      }

      const response = await apiStripe.createCheckoutSession(data)

      if (response?.data.session) {
        segmentCheckoutStarted(user, data)
        // Redirect to Stripe Checkout
        const result = await stripe.redirectToCheckout({
          sessionId: response.data.session.id,
        })
        if (result.error) {
          throw new Error(result.error.message)
        }
      } else {
        throw new Error(
          response.data.message || 'Failed to create checkout session.'
        )
      }
    } catch (error) {
      Sentry.captureException(error)
      toast({ variant: 'destructive', description: error.message })
    } finally {
      setLoading(false)
    }
  }

  const handleRequestCall = async (event) => {
    event.preventDefault() // Prevent form submission from refreshing the page
    setRequestCallLoading(true)
    try {
      await apiUsers.send_email(user.public_id, {
        template: 'generic_email.html',
        subject: 'Billing Call Request',
        to_email: 'hello@autron.ai',
        message: `${feedback}`,
      })
      toast({
        variant: 'success',
        description:
          'Call request sent successfully. We will contact you shortly.',
      })
      setShowBillingModal(false)
    } catch (error) {
      toast({
        variant: 'destructive',
        description: 'Failed to send call request. Please try again.',
      })
    }
    setRequestCallLoading(false)
  }

  const manageClick = async () => {
    setShowBillingModal(false)
    setManageBillingLoading(true)
    try {
      const response = await apiStripe.createCustomerPortal()
      if (response?.data.session_url) {
        window.location.href = response.data.session_url
      } else {
        throw new Error(response.data.message)
      }
    } catch (error) {
      toast({
        variant: 'destructive',
        description: error?.message,
      })
    }
    setManageBillingLoading(false)
  }

  const scrollToSuccess = () => {
    return successCardEndRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  useEffect(() => {
    scrollToSuccess()
  }, [session])

  const onSuccess = async (sessionId) => {
    setLoading(true)
    await apiStripe
      .getCheckoutSession(sessionId)
      .then(function (response) {
        return response
      })
      .then(function (response) {
        if (response?.data.session) {
          setSession(response.data.session)
        } else {
          throw new Error(response.data.message)
        }
      })
      .catch(function (error) {
        toast({
          variant: 'destructive',
          destructive: error?.message,
        })
      })
    setLoading(false)
  }

  useEffect(() => {
    if (sessionId) {
      onSuccess(sessionId)
    }
    if (canceled) {
      segmentCheckoutCancelled(user)
      toast({
        variant: 'destructive',
        description: 'Checkout canceled :(',
      })
    }
  }, [sessionId, canceled]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const p = history.location.pathname.split('/').pop()
    setPage(p)
  }, [history.location.pathname])

  useEffect(() => {
    const id =
      (window.Rewardful && window.Rewardful.referral) ||
      'checkout_' + user?.public_id + '_' + new Date().getTime()
    setStripeClientReferenceId(id)
  }, [user])

  const [siMerged, setSiMerged] = useState([])

  useEffect(() => {
    const subscriptionItemMerger = (stripeSubscriptionItems) => {
      const mergedSubscriptions = stripeSubscriptionItems.reduce(
        (acc, item) => {
          const key = item.subscription

          if (!acc[key]) {
            acc[key] = { ...item, licensed_price_unit_amount: null }
          }

          if (item.price_billing_scheme === 'tiered') {
            acc[key] = { ...acc[key], ...item }
          }

          if (
            item.price_billing_scheme === 'per_unit' &&
            item.price_recurring_usage_type === 'licensed'
          ) {
            acc[key].licensed_price_unit_amount = item.price_unit_amount
          }

          return acc
        },
        {}
      )

      setSiMerged(Object.values(mergedSubscriptions))
    }

    subscriptionItemMerger(user.company.stripe_subscription_items)
  }, [user.company.stripe_subscription_items])

  const subscriptionColumns = useMemo(
    () => [
      {
        Header: 'Plan',
        accessor: 'price_product_name',
        sortable: false,
        Cell: (props) => <span className="font-semibold">{props.value}</span>,
      },
      {
        Header: 'Status',
        accessor: 'subscription_status',
        sortable: false,
        Cell: (props) => (
          <Badge
            className={`capitalize ${
              ['past_due'].includes(props.value) ||
              (props.value === 'active' &&
                props.row.original['subscription_cancel_at_period_end'] &&
                props.row.original['subscription_cancel_at'])
                ? 'border-yellow-300 bg-yellow-50 text-yellow-400 dark:border-yellow-100 dark:bg-transparent dark:text-yellow-200'
                : ['active', 'trialing'].includes(props.value)
                ? 'border-lime-300 bg-lime-50 text-lime-400 dark:border-lime-200 dark:bg-transparent dark:text-lime-300'
                : 'border-red-300 bg-red-50 text-red-400 dark:border-red-200 dark:bg-transparent dark:text-red-300'
            }`}
            variant="outline"
          >
            {(props.value === 'active' || props.value === 'trialing') &&
            props.row.original['subscription_cancel_at_period_end'] &&
            props.row.original['subscription_cancel_at']
              ? 'Cancels ' +
                new DateTime.fromISO(
                  props.row.original['subscription_cancel_at']
                )
                  .setLocale('en-US')
                  .toLocaleString(DateTime.DATE_MED)
              : props.value}
          </Badge>
        ),
      },
      {
        Header: 'Price',
        accessor: 'price_unit_amount',
        sortable: false,
        Cell: (props) => priceCell(props),
      },
      {
        Header: 'Next Invoice',
        accessor: 'subscription_current_period_end',
        sortable: false,
        Cell: (props) =>
          ['active', 'trialing'].includes(
            props.row.original['subscription_status']
          ) && !props.row.original['subscription_cancel_at_period_end']
            ? new DateTime.fromISO(props.value)
                .setLocale('en-US')
                .toLocaleString(DateTime.DATE_MED)
            : null,
      },
      {
        Header: 'Next Invoice Amount Due',
        accessor: 'price_recurring_usage_type',
        sortable: false,
        Cell: (props) =>
          ['active', 'trialing'].includes(
            props.row.original['subscription_status']
          ) && !props.row.original['subscription_cancel_at_period_end']
            ? invoices?.length > 0
              ? Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: props.row.original['price_currency'],
                  minimumFractionDigits: 2,
                }).format(
                  invoices.find(
                    (i) =>
                      i.subscription_id === props.row.original['subscription']
                  )?.amount_due / 100
                )
              : ''
            : null,
      },
    ],
    [prices, invoices]
  )

  const priceCell = (props) => {
    let price = 0
    if (props.row.original['price_billing_scheme'] === 'per_unit') {
      price = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: props.row.original['price_currency'],
        minimumFractionDigits: 0,
      }).format(props.value / 100)
    } else {
      // metered
      const p = prices?.find(
        (price) => price.id === props.row.original['price_id']
      )
      if (!p) return 'Custom' // handles custom Pro prices e.g. CPR
      price =
        new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: props.row.original['price_currency'],
          minimumFractionDigits: 0,
        }).format(props.row.original['licensed_price_unit_amount'] / 100) +
        (p?.tiers.length > 0 ? ' + % of Ad Sales' : '')
    }

    return (
      <Fragment>
        <span>
          {price +
            ' / ' +
            (props.row.original['price_recurring_interval_count'] === 1
              ? ''
              : ' ' +
                props.row.original['price_recurring_interval_count'] +
                ' ') +
            props.row.original['price_recurring_interval']}
        </span>
      </Fragment>
    )
  }

  return (
    <>
      <Dialog open={showBillingModal} onOpenChange={setShowBillingModal}>
        <DialogContent className="sm:max-w-[425px] max-w-[300px] rounded-lg">
          <DialogHeader>
            <DialogTitle className="font-space-grotesk font-normal tracking-wide text-xl">
              Manage Your Billing with Ease
            </DialogTitle>
            <DialogDescription>
              We prioritize your experience. If you have any questions or
              concerns about your billing, we're here to assist you.
            </DialogDescription>
          </DialogHeader>
          <div className="flex flex-col gap-4">
            <form onSubmit={handleRequestCall} className="grid gap-4">
              <Textarea
                placeholder="Is there anything bothering you? Let us know how we can help."
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
                className="h-24"
              />
              <Button
                type="submit"
                disabled={requestCallLoading}
                className="w-full font-geist font-medium"
              >
                {requestCallLoading ? (
                  <LoadingSpinner className="w-4 h-4" />
                ) : (
                  'Request a Call'
                )}
              </Button>
            </form>
            <Button
              variant="outline"
              disabled={manageBillingLoading}
              onClick={manageClick}
              className="w-full font-geist font-medium"
            >
              {manageBillingLoading ? (
                <LoadingSpinner className="w-4 h-4" />
              ) : (
                'Go to Billing'
              )}
            </Button>
          </div>
          <DialogFooter className="text-sm text-muted-foreground font-geist">
            Your success is our success. We're committed to providing seamless
            support to ensure you get the most out of Autron.
          </DialogFooter>
        </DialogContent>
      </Dialog>
      {isLoading ? (
        <LoadingSpinner /> // used for initial load
      ) : (
        {
          billing: userUpgradePrice ? (
            userUpgradePrice.product_name === 'Custom' ? (
              <div className="p-6">
                <h2 className="text-2xl tracking-normal pt-0 font-space-grotesk font-normal mb-4">
                  Become an Agency Partner
                </h2>
                <p className="mb-5">
                  Grow your agency with Autron's AI decision engine and
                  automated advertising software. Spend less time managing
                  campaigns and more time driving client success.
                </p>
                <Button
                  disabled={loading || !stripe}
                  value={userUpgradePrice.product_id} // pass product_id here instead of price_id since required for ad hoc price_data
                  onClick={(e) => pricingCardBtnClick(stripe, e)}
                >
                  {loading ? <LoadingSpinner className="w-4 h-4" /> : null}
                  Upgrade to Agency Partner
                </Button>
              </div>
            ) : (
              <Payment />
            )
          ) : (
            <Card className="border-none shadow-none flex flex-col gap-2">
              <CardHeader className="pt-0 px-3 sm:px-6">
                <CardTitle className="tracking-normal p-0 pt-0 font-space-grotesk font-normal">
                  Billing
                </CardTitle>
              </CardHeader>
              <CardContent>
                {user.company.stripe_subscription_items ? (
                  <CustomTable
                    columns={subscriptionColumns}
                    data={siMerged}
                    loading={isLoading}
                    total={user.company.stripe_subscription_items.length}
                  />
                ) : null}
              </CardContent>
              <CardFooter>
                {user.company.stripe_subscription_items ? (
                  <Button
                    disabled={loading}
                    onClick={() => setShowBillingModal(true)}
                  >
                    {loading ? <LoadingSpinner className="w-4 h-4" /> : null}
                    Manage Billing
                  </Button>
                ) : null}
              </CardFooter>
            </Card>
          ),
          success: (
            <Card className="text-center">
              <CardHeader>
                <CardTitle>Thank you, {user.firstname}!</CardTitle>
              </CardHeader>
              <CardContent>
                <p className="mb-5">
                  You are now subscribed to Autron{' '}
                  <strong>
                    {session?.line_items?.data[0].price.product.name}
                  </strong>{' '}
                  plan. <br /> We're excited to help you take your business to
                  the next level.
                </p>
                <Button onClick={() => history.push('/admin/dashboard')}>
                  Back to dashboard
                </Button>
              </CardContent>
              <div ref={successCardEndRef} />
            </Card>
          ),
          canceled: 'canceled', // not used, do it from billing with url param
        }[page]
      )}
    </>
  )
}

export default Billing
